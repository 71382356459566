<template>
  <div class="shelf-footer">
    <el-button class="shelf-footer-save-btn" @click="close">取消</el-button>
    <el-button type="primary" class="shelf-footer-save-btn" @click="submit">保存</el-button>
  </div>
</template>

<script setup>
const emit = defineEmits(['submit', 'close'])
const submit = () => {
  emit('submit')
}
const close = () => {
  emit('close')
}
</script>
<script>
import {defineComponent} from "vue";


export default defineComponent({
  name: "ShelfFooter",
});
</script>

<style lang="scss" scoped>
.shelf-footer {
  position: absolute;
  bottom: 0;
  left: 32px;
  width: calc(100% - 64px);
  border-top: 1px solid #DCDEE0;
  padding-top: 12px;
  padding-bottom: 12px;
  display: flex;
  justify-content: flex-end;

  &-save-btn {
    width: 112px;
    height: 40px;
  }
}

</style>
